import React, { FC } from 'react'
import { ModalProps, ModalView } from '../../../components/organisms/Modal'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { editDeviceFn, getDevicesFn } from '../../../api'
import { useUserContext } from '../../../contexts/UserContext'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { BlackBoldParagraph } from '../../../components/atoms/Paragraph'
import { Switch } from '../../../components/molecules/Switch'
import Button from '../../../components/molecules/Button'
import { toast } from 'react-toastify'
import { DeviceUpdate } from '../../../@types'


interface FormProps {
  hidden_settings: boolean
  debug_css: boolean
  disable_call_ads: boolean
  allow_screenshots: boolean
  enable_system_logger: boolean
  //location: string
  //preferred_locale: string
}

const EditDevice: FC<ModalProps> = ({ onClose }) => {

  const [t] = useTranslation()
  const params = useParams<{ id: string }>()
  const queryClient = useQueryClient()
  const { state } = useUserContext()
  const { i_realm } = state?.realm

  const { data: device } = useQuery(['devices', i_realm], getDevicesFn, {
    select: (data) => data.filter(({ i_device }) => +params.id! === i_device)?.[0],
  })

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<FormProps>()

  const { mutate } = useMutation((data: Partial<DeviceUpdate>) => editDeviceFn({ ...data, id: +params.id! }), {
    onSuccess: ({ username, type }) => {
      queryClient.invalidateQueries('devices')
      toast.success(t('modal.editAdmin.editSuccess', { type, username }))
      onClose()
    },
  })

  const onSubmit = (data: FormProps) => {
    mutate({
      hidden_settings: Number(data.hidden_settings),
      debug_css: Number(data.debug_css),
      disable_call_ads: Number(data.disable_call_ads),
      allow_screenshots: Number(data.allow_screenshots),
      enable_system_logger: Number(data.enable_system_logger),
    })
  }

  console.log('device', device)
  return (
    <ModalView title={t('modal.editDevice.title')} style={{ maxWidth: '450px' }} onClose={onClose}>
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Field>
              <Controller
                name="hidden_settings"
                control={control}
                defaultValue={Boolean(device?.hidden_settings)}
                render={({ field: { value, onChange } }) => (
                  <div
                    style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}
                  >
                    <BlackBoldParagraph text={t('common.hidden_settings')} />
                    <Switch checked={value} onToggle={onChange} />
                  </div>
                )}
              />
              <div>
                <Controller
                  name="debug_css"
                  control={control}
                  defaultValue={Boolean(device?.debug_css)}
                  render={({ field: { value, onChange } }) => (
                    <div
                      style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}
                    >
                      <BlackBoldParagraph text={t('common.debug')} />
                      <Switch checked={value} onToggle={onChange} />
                    </div>
                  )}
                />
              </div>
              <div>
                <Controller
                  name="disable_call_ads"
                  control={control}
                  defaultValue={Boolean(device?.disable_call_ads)}
                  render={({ field: { value, onChange } }) => (
                    <div
                      style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}
                    >
                      <BlackBoldParagraph text={t('common.disable_call_ads')} />
                      <Switch checked={value} onToggle={onChange} />
                    </div>
                  )}
                />
              </div>
            </Field>
            <Field>

              <div>
                <Controller
                  name="allow_screenshots"
                  control={control}
                  defaultValue={Boolean(device?.allow_screenshots)}
                  render={({ field: { value, onChange } }) => (
                    <div
                      style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}
                    >
                      <BlackBoldParagraph text={t('common.allow_screenshots')} />
                      <Switch checked={value} onToggle={onChange} />
                    </div>
                  )}
                />
              </div>
              <div>
                <Controller
                  name="enable_system_logger"
                  control={control}
                  defaultValue={Boolean(device?.enable_system_logger)}
                  render={({ field: { value, onChange } }) => (
                    <div
                      style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}
                    >
                      <BlackBoldParagraph text={t('common.enable_system_logger')} />
                      <Switch checked={value} onToggle={onChange} />
                    </div>
                  )}
                />
              </div>
            </Field>
          </div>
          <Button style={{ alignSelf: 'flex-end' }} appearance="blue" type="submit" disabled={!isDirty}>
            {t('modal.editAdmin.edit')}
          </Button>
        </Form>
      </Content>
    </ModalView>
  )
} //

export default EditDevice

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`

const Field = styled.div`
  display: flex;
  margin-bottom: 16px;
  flex-flow: wrap;
  gap: 12px;
  & > * {
    flex: 1;
    //min-width: 180px;
  }
`
