/* eslint-disable */
import {
  LoginRequest,
  LoginResponse,
  Realm,
  User,
  Contact,
  BundleMapping,
  InfoMessage,
  FeatureGroup,
  ActivityLog,
  AdminUser,
  AdminUserCredentials,
  RefreshRequest,
  Broadcast,
  BroadcastResponse,
  BroadcastChannel,
  Device,
  Customer,
  AppVersion,
  AppVersionRequest,
  UploadedFile,
  UploadedFileResponse,
  UserIdentityUniquenessResponse, RealmMailSettings, EmailTemplate, EmailSent, DeviceUpdate,
} from '@types'
import api from '.'
import { QueryFunctionContext } from 'react-query'

export const loginUserFn = async (user: LoginRequest) => {
  const response = await api.post<LoginResponse>('auth/login', user)
  return response.data
}

export const refreshUserFn = async (data: RefreshRequest) => {
  const response = await api.post<LoginResponse>('auth/refresh', data)
  return response.data
}

export const logoutUserFn = async () => {
  const response = await api.post<{ ack?: 'ok' }>('auth/logout')
  return response.data
}

export const getMeFn = async () => {
  const response = await api.get<AdminUser>('users/me')
  return response.data
}

export const getVersionFn = async () => {
  const response = await api.get<{ version: string; author: string }>('app/version')
  return response.data
}
export const getChangelogFn = async () => {
  const response = await api.get<{ markdown: string; html: string }>('app/changelog')
  return response.data
}

export const createAdminFn = async (credentials: AdminUserCredentials) => {
  const response = await api.post<AdminUser>('users/admin', credentials)
  return response.data
}

export const editAdminFn = async ({ id, ...credentials }: Partial<AdminUserCredentials> & { id: number }) => {
  const response = await api.put<AdminUser>(`users/admin/${id}`, credentials)
  return response.data
}

export const getAdminsFn = async ({ queryKey }: QueryFunctionContext<[string, number]>) => {
  const [_, i_realm] = queryKey
  const response = await api.get<AdminUser[]>('users/admins', { params: { i_realm } })
  return response.data
}

export const getRealmsFn = async () => {
  const response = await api.get<Realm[]>('realms')
  return response.data
}

export const getRealmMailSettingsFn = async ({ queryKey }: QueryFunctionContext<[string, number]>) => {
  const [_, i_realm] = queryKey
  const response = await api.get<RealmMailSettings>(`realms/${i_realm}/mail_settings`)
  return response.data
}

export const createRealmMailSettingsFn = async (data: Partial<RealmMailSettings>) => {
  const response = await api.post<RealmMailSettings>(`realms/${data.i_realm}/mail_settings`, data)
  return response.data
}

export const updateRealmMailSettingsFn = async ({ id, ...data }: Partial<RealmMailSettings>) => {
  const response = await api.put<RealmMailSettings>(`realms/${data.i_realm}/mail_settings/${id}`, data)
  return response.data
}

export const createRealmFn = async ({ i_realm, ...data }: Partial<Realm>) => {
  const response = await api.post<Realm>(`realms/`, data)
  return response.data
}

export const updateRealmFn = async ({ i_realm, ...data }: Partial<Realm> & Record<string, any>) => {
  const { cert, pub_key, priv_key, certificate, ...rest } = data
  const response = await api.put<Realm>(`realms/${i_realm}`, {
    ...rest,
    cert: cert ? cert : null,
    pub_key: pub_key ? pub_key : null,
    priv_key: priv_key ? priv_key : null,
    certificate: certificate ? certificate : null,
  })
  return response.data
}

export const getFeatureGroupsFn = async ({ queryKey }: QueryFunctionContext<[string, number]>) => {
  const [_, i_realm] = queryKey
  const response = await api.get<FeatureGroup[]>('feature_groups', { params: { i_realm } })
  return response.data
}

export const createFeatureGroupsFn = async (data: Partial<FeatureGroup>) => {
  const response = await api.post<FeatureGroup[]>('feature_groups', data)
  return response.data
}

export const editFeatureGroupsFn = async ({ i_featuregroup, ...data }: Partial<FeatureGroup>) => {
  const response = await api.put<FeatureGroup[]>(`feature_groups/${i_featuregroup}`, data)
  return response.data
}

export const deleteFeatureGroupFn = async (id: number) => {
  return await api.delete<FeatureGroup[]>(`feature_groups/${id}`)
}

export const validateUserIdentityFn = async ({ queryKey }: QueryFunctionContext<(string | number | undefined)[]>) => {
    const [_queryName, user_id, first_name, last_name, info, email ] = queryKey
    //`/v3/admin/user_identity_uniqueness`
  const response = await api.post<UserIdentityUniquenessResponse>('user_identity_uniqueness', {
    user_id,
    first_name,
    last_name,
    info,
    email
  })
  return response.data
}

export const createAutoconfigFn = async ({ queryKey }: QueryFunctionContext<(string | number | undefined | boolean)[]>) => {
  const [
    _queryName,
    user_id,
    realm_id,
    feature_group_id,
    first_name,
    last_name,
    info,
    bundle_mapping_id,
    auto_visible,
    email
  ] = queryKey

  const response = await api.get('createautoconfig', {
    params: {
      user_id,
      ...(auto_visible !== undefined && {
        auto_visible,
      }),
      ...(feature_group_id && {
        feature_group_id,
      }),
      ...(realm_id && {
        realm_id,
      }),
      ...(first_name && {
        first_name,
      }),
      ...(last_name && {
        last_name,
      }),
      ...(info && {
        info,
      }),
      ...(bundle_mapping_id && {
        bundle_mapping_id,
      }),
      ...(email && { email }),
    },
    responseType: 'blob',
  })
  return response.data
}

export const getUsersFn = async ({ queryKey }: QueryFunctionContext<[string, number]>) => {
  const [_, i_realm] = queryKey
  const response = await api.get<User[]>('reports/users/registered/', { params: { i_realm } })

  return response.data
}

export const deleteUserFn = async ({username, i_realm} : { username: string, i_realm: number}) => {
  await api.delete<{ ack?: 'ok' }>(`users/delete/${username}`, { params: { i_realm } })
}

export const getCustomersFn = async () => {
  const response = await api.get<Customer[]>(`customers`, {
    params: {
      limit: 10000,
    },
  })
  return response.data
}
export const updateCustomerFn = async ({ i_customer: id, i_featuregroup, ...data }: Partial<Customer>) => {
  await api.put<Customer>(`customers/${id}`, { feature_group: i_featuregroup, ...data })
}

export const getDevicesFn = async ({ queryKey }: QueryFunctionContext<[string, number]>) => {
  const [_, i_realm] = queryKey
  const response = await api.get<Device[]>('devices/', {
    params: {
      i_realm,
      limit: 10000,
    },
  })

  return response.data
}


export const editDeviceFn = async ({id , ...data}: Partial<DeviceUpdate> & { id: number }) => {
  const response = await api.put<AdminUser>(`devices/${id}`, data)
  return response.data
}

export const getContactsFn = async ({ queryKey }: QueryFunctionContext<[string, number]>) => {
  const [_, i_realm] = queryKey
  const response = await api.get<Contact[]>('reports/users/roster_contacts', {
    params: {
      i_realm,
    },
  })
  return response.data
}

export const updateContactsFn = async (data: Partial<Contact>) => {
  const formData = new FormData()

  Object.keys(data).forEach((key) => {
    const value = data[key as keyof Contact]
    if (value) {
      formData.append(key, value.toString())
    }
  })

  const response = await api.put<Contact>(`reports/users/roster_contacts/${data.id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}

export const getInfoMessagesFn = async ({ queryKey }: QueryFunctionContext<[string, number]>) => {
  const [_, i_realm] = queryKey
  const response = await api.get<InfoMessage[]>('info_messages', { params: { i_realm } })
  return response.data
}

export const createInfoMessageFn = async (message: InfoMessage) => {
  const response = await api.post<InfoMessage>('info_messages', message)
  return response.data
}

export const sendPushMessageFn = async (push: { message: string; user_ids?: string[]; info_message_id?: number }) => {
  const response = await api.post<{
    errors: {
      [key: string]: string
    }
    sent: {}
  }>('send_push', push)
  return response.data
}

export const getBroadcastsFn = async ({ queryKey }: QueryFunctionContext<[string, number]>) => {
  const [_, i_realm] = queryKey
  const response = await api.get<BroadcastResponse[]>('broadcast/', { params: { i_realm, limit: 1000 } })
  return response.data
}

export const createBroadcastFn = async ({
  text,
  user_ids,
  channel,
  i_realm,
  message_type,
  files,
}: {
  text: string
  user_ids: string[]
  channel: string
  i_realm: number,
  message_type: 'text' | 'html'
  files: UploadedFile[]
}) => {
  const response = await api.post<Broadcast>('broadcast/', {
    user_ids,
    recipient_type: 'USERID',
    i_realm,
    message: {
      i_broadcast_channel: +channel,
      sender_name: '',
      sender_address: '',
      body: text,
      language: 'en',
      message_type,
      files,
    },
    i_tag_list: 0,
    publishing_date: Date.now() / 1000 - 86400,
    enabled: 1,
  })
  return response.data
}

export const deleteBroadcastFn = async (id: number) => {
  await api.delete<{ ack?: 'ok' }>(`broadcast/${id}`)
}

export const getBroadcastsChannelsFn = async ({ queryKey }: QueryFunctionContext<[string, number]>) => {
  const [_, i_realm] = queryKey
  const response = await api.get<BroadcastChannel[]>('broadcast/channels', { params: { i_realm } })
  return response.data
}

export const createBroadcastsChannelFn = async ({ name, i_realm }: { name: string; i_realm: number }) => {
  const response = await api.post<BroadcastChannel>('broadcast/channels', {
    i_realm,
    channel_id: name,
    name: name,
    username: name,
    profile_pic_url: '',
    channel_type: 'ro',
    creator: 0,
  })
  return response.data
}

export const updateInfoMessageFn = async ({ id, ...data }: Partial<InfoMessage>) => {
  await api.put<InfoMessage>(`info_messages/${id}`, data)
}

export const deleteInfoMessageFn = async (id: number) => {
  return await api.delete<{ ack?: 'ok' }>(`info_messages/${id}`)
}

export const getBundleMappingsFn = async ({ queryKey }: QueryFunctionContext<[string, number]>) => {
  const [_, i_realm] = queryKey
  const response = await api.get<BundleMapping[]>('bundle_mappings', { params: { i_realm } })
  return response.data
}

export const getActivityLogFn = async ({ queryKey }: QueryFunctionContext<[string, number]>) => {
  const [_, i_realm] = queryKey
  const response = await api.get<ActivityLog[]>('reports/activity', { params: { i_realm, limit: 10000 } })
  return response.data
}

export const getAppVersionsFn = async ({ queryKey }: QueryFunctionContext<[string, number]>) => {
  const [_, i_realm] = queryKey
  const response = await api.get<AppVersion[]>('application_versions/', { params: { i_realm } })
  return response.data
}

export const createAppVersionFn = async (data: AppVersionRequest) => {
  const response = await api.post<AppVersion>('application_versions/', data)
  return response.data
}

export const updateAppVersionFn = async ({ id, ...data }: Partial<AppVersion>) => {
  const response = await api.put<AppVersion>(`application_versions/${id}`, data)
  return response.data
}

export const uploadFile = async (files: File[]): Promise<UploadedFile[]> => {
  const requestData = new FormData()
  files.forEach((file: File) => {
    requestData.append(`files`, file)
  })
  const response = await api.post<UploadedFileResponse>('upload', requestData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data?.files || []
}

export const deleteFile = async (id: string) => {
  const response = await api.delete(`upload/${id}`)
  return response.data
}


export const importUsersFn = async (file: File, i_realm?: number) => {
  const formData = new FormData()
  formData.append('importFile', file)
  const resp = await api.post('import_users_autoconfig', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params: i_realm ? { realm_id: i_realm } : {} ,
  })
  return resp.data
}

export const emailTemplatesFn = async ({ queryKey }: QueryFunctionContext<[string, number]>) => {
  const [_, i_realm] = queryKey
  const response = await api.get<EmailTemplate[]>(`email_templates/`, { params: { i_realm } })
  return response.data
}

export const emailSentLogsFn = async ({ queryKey }: QueryFunctionContext<[string, number]>) => {
  const [_, i_realm] = queryKey
  const response = await api.get<EmailSent[]>(`email_sent/`, { params: { i_realm } })
  return response.data
}

export const emailResendMailFn = async (id: number) => {
  const response = await api.get<EmailSent>(`email_sent/resend/${id}`)
  return response.data
}

export const updateEmailTemplatesFn = async (data: EmailTemplate) => {
  const { id, ...rest } = data
  const response = await api.put<EmailTemplate>(`email_templates/${id}`, {
    ...rest,
  })
  return response.data
}

export const crateEmailTemplatesFn = async (data: Partial<EmailTemplate>) => {
  const response = await api.post<EmailTemplate>(`email_templates/`,  data)
  return response.data
}

export const deleteEmailTemplateFn = async (id: number) => {
  return await api.delete(`email_templates/${id}`)
}
