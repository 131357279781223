import React from 'react'
import { useGetModalState, useCloseModal } from './hooks'

import Modal from './Modal'

import About from '../navigation/modals/About'
import AddAdmin from 'pages/admins/modals/AddAdmin'
import EditAdmin from 'pages/admins/modals/EditAdmin'
import AddUser from 'pages/users/modals/AddUser'
import EditUser from 'pages/users/modals/EditUser'
import DeleteUser from 'pages/users/modals/DeleteUser'
import EditContact from 'pages/contacts/modals/EditContact'
import AddFeatureGroup from 'pages/feature-groups/modals/AddFeatureGroup'
import EditFeatureGroup from 'pages/feature-groups/modals/EditFeatureGroup'
import NewInfoMessage from 'pages/info-messages/modals/NewInfoMessage'
import NewBroadcast from 'pages/broadcast/modals/NewBroadcast'
import AddChannel from 'pages/broadcast/modals/AddChannel'
import AddApp from 'pages/apps/modals/AddApp'
import AddRealm from 'pages/realms/modals/AddRealm'
import Changelog from '../navigation/modals/Changelog'
import EditRealm from '../../../pages/realms/modals/EditRealm'
import AddEditRealMailSettings from '../../../pages/realms/modals/AddEditRealMailSettings'
import AddEditEmailTemplate from '../../../pages/emails/modals/AddEditEmailTemplate'
import ViewEmailDialog from '../../../pages/emails/modals/ViewEmailDialog'
import EditDevice from '../../../pages/devices/modals/EditDevice'

export interface ModalProps {
  onClose: () => void
}

export const MODALS = {
  about: 'about',
  addUser: 'add-user',
  editUser: 'edit-user',
  deleteUser: 'delete-user',
  editContact: 'edit-contact',
  addFeatureGroup: 'add-feature-group',
  addRealm: 'add-realm',
  editRealm: 'edit-realm',
  editRealmMailSettings: 'edit-realm-mail-settings',
  editFeatureGroup: 'edit-feature-group',
  newInfoMessage: 'new-info-message',
  newBroadcast: 'new-broadcast',
  addChannel: 'add-channel',
  addAdmin: 'add-admin',
  editAdmin: 'edit-admin',
  editDevice: 'edit-device',
  addApp: 'add-app',
  changelog: 'changelog',
  addEditEmailTemplate: 'add-edit-email-template',
  viewSentEmail: 'view-sent-email',
}

const modals = {
  [MODALS.about]: About,
  [MODALS.addUser]: AddUser,
  [MODALS.editUser]: EditUser,
  [MODALS.deleteUser]: DeleteUser,
  [MODALS.editContact]: EditContact,
  [MODALS.addFeatureGroup]: AddFeatureGroup,
  [MODALS.addRealm]: AddRealm,
  [MODALS.editRealm]: EditRealm,
  [MODALS.editRealmMailSettings]: AddEditRealMailSettings,
  [MODALS.editFeatureGroup]: EditFeatureGroup,
  [MODALS.newInfoMessage]: NewInfoMessage,
  [MODALS.newBroadcast]: NewBroadcast,
  [MODALS.addChannel]: AddChannel,
  [MODALS.addAdmin]: AddAdmin,
  [MODALS.editAdmin]: EditAdmin,
  [MODALS.addApp]: AddApp,
  [MODALS.changelog]: Changelog,
  [MODALS.addEditEmailTemplate]: AddEditEmailTemplate,
  [MODALS.viewSentEmail]: ViewEmailDialog,
  [MODALS.editDevice]: EditDevice,
}

const Modals = () => {
  const { mountedModal, isOpened } = useGetModalState()
  const ModalContent = !!mountedModal && modals[mountedModal]
  const onClose = useCloseModal()

  if (!ModalContent) {
    return null
  }

  return (
    <Modal open={isOpened} onClose={onClose}>
      <ModalContent onClose={onClose} />
    </Modal>
  )
}

export * from './Modal'
export default Modals
